import React from 'react';
import {PowerballResultTable, LadderResultTable, LadderGameStats, PoweballGameStats} from "./game_state";
import {GameResult, VIEW_TYPE} from "../interface";
import {PowerballGameGraph} from "./game_graph_poweball";
import {LadderGameGraph} from "./game_graph_ladder";

function GameStateLayout({gameResults, viewType}: {gameResults: GameResult[]; viewType: VIEW_TYPE}) {
    return (
        viewType != 'ladder'
            ? <PowerballLayout gameResults={gameResults} viewType={viewType} />
            : <LadderLayout gameResults={gameResults} />
    );
}

export default GameStateLayout;

function PowerballLayout({gameResults, viewType}: {gameResults: GameResult[]; viewType: VIEW_TYPE}){
    return (
        <>
            <PoweballGameStats gameResults={gameResults} viewType={viewType} />
            <PowerballGameGraph gameResults={gameResults} />
            <PowerballResultTable gameResults={gameResults} />
        </>
    )
}
function LadderLayout({gameResults}: {gameResults: GameResult[];}){
    return (
        <>
            <LadderGameStats gameResults={gameResults} />
            <LadderGameGraph gameResults={gameResults} />
            <LadderResultTable gameResults={gameResults} />
        </>
    )
}