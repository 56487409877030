export const ef = {};
export const ef_i = {};
export const ef_timeout = {};
export default class SqsEffect {
    constructor(name) {
        this.n = name
    }

    set(st, fc){
        if (!st || !st.imageSize || !st.frame || !st.vector || !st.speed || !st.element) {
            console.error('Invalid arguments: some required properties are missing.');
            return;
        }
        ef[this.n] = [0,0];
        this.close(this.n);

        const a  = parseInt(st.imageSize[0], 10);
        const b  = parseInt(st.imageSize[1], 10);
        const f  = parseInt(st.frame, 10);
        const l  = st.vector;
        const s  = parseInt(st.speed, 10);
        const ele = document.querySelector(st.element);
        if (!ele) {
            console.error('Invalid arguments: element not found.');
            return;
        }
        const lp = st.loop || false;
        const tim = st.timeout || false;
        if (tim && typeof tim !== 'number') {
            console.error('Invalid arguments: timeout should be a number.');
            return;
        }
        if (isNaN(a) || isNaN(b) || isNaN(f) || isNaN(s) || isNaN(l[0]) || isNaN(l[1])) {
            console.error('Invalid arguments: some properties are not numbers.');
            return;
        }
        const t = (s / f);

        let remain_time = 0
        ef_timeout[this.n] = setInterval(()=>{
            remain_time++;
        }, 100)


        let i_cnt = 1;
        ef_i[this.n] = setInterval(()=>{
            this.stP(a, b, l, ele);
            this.stNIdx(l, f);

            if( tim && tim === remain_time * 100 ){
                this.close(this.n);
            }else if( lp === false && i_cnt === f ){
                this.close(this.n);
                if(fc){
                    fc()
                }
            }
            i_cnt++;
        }, t)
    }

    stP(a, b, l, ele){
        const ps_x = -1 * (a / l[0]);
        const ps_Y = -1 * (b / l[1]);

        const x = ef[this.n][0];
        const y = ef[this.n][1];

        const s_ps = (ps_x * x) + "px " + (ps_Y * y) + "px";

        if( ele){
            ele.style.backgroundPosition = s_ps
        }else{
            this.close(this.n);
        }
    }

    stNIdx(l, f){
        if(l[0]*ef[this.n][1] + ef[this.n][0] < f-1){
            if(ef[this.n][0] < (l[0] - 1)){
                ef[this.n][0] = ef[this.n][0] + 1;
            }else{
                ef[this.n][0] = 0;
                if(ef[this.n][1] < (l[1] - 1)){
                    ef[this.n][1] = ef[this.n][1] + 1;
                }else{
                    ef[this.n][1] = 0;
                }
            }
        }else{
            ef[this.n][1] = 0;
        }
    }

    close(n) {
        if(n){
            clearInterval(ef_timeout[n]);
            clearInterval(ef_i[n]);
        }else{
            for(const i in ef_timeout){
                clearInterval(ef_timeout[i])
            }
            for(const i in ef_i){
                clearInterval(ef_i[i])
            }
        }
    }

    reset(ele){
        if (typeof ele !== 'string') {
            throw new TypeError('ele parameter must be a string');
        }
        const el = document.querySelector(ele);
        if (!el) {
            throw new Error(`No element found with selector ${ele}`);
        }
        el.style.backgroundPosition = "0 0";
    }
}