import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import {isMobile} from "react-device-detect";
// Error
import ErrorPage from "src/apps/error/error-page";
import NotFoundPage from 'src/apps/error/not-fond-page';
// Root
import RootLayout from "src/layouts/root";
import HomePage from "src/apps/index";
// Room
import PickRoom from 'src/apps/room/pickRoom';
// Routes
import communityRoute from 'src/apps/community/_route';
import sportsRoute from 'src/apps/sports/_route';
import serviceCenterRoute from 'src/apps/service_center/_route';
import minigameRoute from 'src/apps/minigame/_route';
import rankingRoute from 'src/apps/ranking/_route';
import roomRoute from 'src/apps/room/_route';
import marketRoute from 'src/apps/market/_route';
import userRoute from 'src/apps/user/_route';
import MiniGameLayout from 'src/apps/minigame/_layout';
// Css
import 'bootstrap/dist/css/bootstrap.min.css';
import 'src/asset/css/css.css';
import HappyBallGameBoardIframe from "./apps/minigame/happyball/game/mini_game_board_iframe";

// game
import LoginRequired from "./components/loginRequired";
import ChangePasswordPage from "./apps/user/info/popup/change-password";
import policyRoute from "./apps/policy/_route";
import MiniGameBoardIframe from "./apps/minigame/happyball/game/mini_game_board_iframe";
import Minigame from "./apps/minigame/home";
import SuperRootLayout from "./layouts/_root";


const route = createBrowserRouter([
  {
    path: "/",
    element: <SuperRootLayout />,
    children: [
      {
        path: "/",
        element: <Minigame gameFetch={"happyball"} />,
        errorElement: <ErrorPage />,
        children: [
          // { path: "", element: <MiniGameLayout/>},
          // { path: "/home", element: <HomePage /> },
          // // { path: "/game/", element: <HappyBallGameBoardIframe /> },
          // serviceCenterRoute,
          // userRoute,
          // communityRoute,
          // sportsRoute,
          // minigameRoute,
          // rankingRoute,
          // roomRoute,
          // marketRoute,
          // policyRoute,
          // { path: "*", element: <NotFoundPage /> }
        ]
      },

      { path: "/happyball/", element: <MiniGameBoardIframe game={"happyball"} viewType={"reel"} /> },
      { path: "/happyball/reel/", element: <MiniGameBoardIframe game={"happyball"} viewType={"reel"} /> },
      { path: "/happyball/ball/", element: <MiniGameBoardIframe game={"happyball"} viewType={"ball"} /> },
      // { path: "/happyball/ladder/", element: <MiniGameBoardIframe game={"happyball_ladder"} viewType={"ladder"} /> },
      //
      // { path: "/eos5/", element: <MiniGameBoardIframe game={"eos5"} viewType={"reel"} /> },
      // { path: "/eos5/reel/", element: <MiniGameBoardIframe game={"eos5"} viewType={"reel"} /> },
      // { path: "/eos5/ball/", element: <MiniGameBoardIframe game={"eos5"} viewType={"ball"} /> },
      //
      // { path: "/eos3/", element: <MiniGameBoardIframe game={"eos3"} viewType={"reel"} /> },
      // { path: "/eos3/reel/", element: <MiniGameBoardIframe game={"eos3"} viewType={"reel"} /> },
      // { path: "/eos3/ball/", element: <MiniGameBoardIframe game={"eos3"} viewType={"ball"} /> },
      //
      // { path: "/pbg5/", element: <MiniGameBoardIframe game={"pbg5"} viewType={"reel"} /> },
      // { path: "/pbg5/reel/", element: <MiniGameBoardIframe game={"pbg5"} viewType={"reel"} /> },
      // { path: "/pbg5/ball/", element: <MiniGameBoardIframe game={"pbg5"} viewType={"ball"} /> },
    ]
  },

  { path: "/chat-room/:roomId", element: <PickRoom /> },

  // popup
  { path: "/user/change-password", element: <LoginRequired><ChangePasswordPage show={true} setShow={true} /></LoginRequired> },

  // test
  // { path: "/test/", element: < TestPage /> },
  // { path: "/m/register", element: < RegisterPage /> },
])

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <RouterProvider router={route} />
);
