import React from "react"
import { Outlet } from "react-router-dom"

import useUser from "src/hooks/useUser"

export default function LoginRequired({ children, next = true }: { children?: React.ReactNode, next?: boolean }) {
    const { user } = useUser({ next: true, loginRequired: true })
    if (!user?.is_authenticated) {
        return <></>
    }
    return <>
        {children ? children : <Outlet />}
    </>
}