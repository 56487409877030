export default function getSocket(path: string) {
    let local = false
    if (['localhost', '127.0.0.1'].includes(window.location.hostname)) {
        local = true
    }
    let protocol = window.location.protocol === 'https:' ? "wss://" : "ws://"
    let host = local ? window.location.hostname + ':8000' : window.location.host
    const socketUrl = `${protocol}${host}${path}`

    return new WebSocket(socketUrl)
}