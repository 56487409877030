import {GameResult} from "../interface";
import React, {useEffect, useState} from "react";
import {getLadderGraphDataObject} from "./game_state";
import GraphBarCircle from "../../../../components/graph/circle";
import PerCount from "../../per_count";
import {Graph_4} from "../../../../components/graph/graph_sml";

export function LadderGameGraph({gameResults}: { gameResults: GameResult[];}){
    const data = getLadderGraphDataObject(gameResults)
    const START = data.start
    const LINE = data.line
    const END = data.end
    const comb = data.comb

    const [ladderStartWin, setLadderStartWin] = useState<string>('red')
    const [ladderLineWin, setLadderlineWin] = useState<string>('red')
    const [ladderEndWin, setLadderEndWin] = useState<string>('red')
    useEffect(() => {
        setLadderStartWin(START.perCount.left < START.perCount.right ? "red" : "blue")
        setLadderlineWin(LINE.perCount.line3 < LINE.perCount.line4 ? "red" : "blue")
        setLadderEndWin(END.perCount.odd < END.perCount.even ? "red" : "blue")
    }, [gameResults])
    return (
        <>
            <div id={"powerball-result-graph"}>
                <div>
                    <main>
                        <div>
                            <header>좌우</header>
                            <div className={"graph-circle overunder"}>
                                <GraphBarCircle per={Number(START.percent.left)} color={ladderStartWin} />
                                <div className={"inner blue"}>
                                    <div>
                                        <p className={"item-tit " + (ladderStartWin == "blue" ? ladderStartWin : "default")}>좌</p>
                                        <p className={"item-contents"}>
                                            <span>{START.perCount.left}번</span>
                                            <PerCount count={START.percent.left} />
                                        </p>
                                    </div>
                                    <div>
                                        <p className={"item-tit " + (ladderStartWin == "red" ? ladderStartWin : "default")}>우</p>
                                        <p className={"item-contents"}>
                                            <span>{START.perCount.right}번</span>
                                            <PerCount count={START.percent.right} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <header>줄수</header>
                            <div className={"graph-circle overunder"}>
                                <GraphBarCircle per={Number(LINE.percent.line3)} color={ladderLineWin} />
                                <div className={"inner blue"}>
                                    <div>
                                        <p className={"item-tit " + (ladderLineWin == "blue" ? ladderLineWin : "default")}>3줄</p>
                                        <p className={"item-contents"}>
                                            <span>{LINE.perCount.line3}번</span>
                                            <PerCount count={LINE.percent.line3} />
                                        </p>
                                    </div>
                                    <div>
                                        <p className={"item-tit " + (ladderLineWin == "red" ? ladderLineWin : "default")}>4줄</p>
                                        <p className={"item-contents"}>
                                            <span>{LINE.perCount.line4}번</span>
                                            <PerCount count={LINE.percent.line4} />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <header>홀짝</header>
                    <main>
                        <div className={"graph-circle overunder"}>
                            <GraphBarCircle per={Number(END.percent.odd)} color={ladderEndWin} />
                            <div className={"inner blue"}>
                                <div>
                                    <p className={"item-tit " + (ladderEndWin == "blue" ? ladderEndWin : "default")}>홀</p>
                                    <p className={"item-contents"}>
                                        <span>{END.perCount.odd}번</span>
                                        <PerCount count={END.percent.odd} />
                                    </p>
                                </div>
                                <div>
                                    <p className={"item-tit " + (ladderEndWin == "red" ? ladderEndWin : "default")}>짝</p>
                                    <p className={"item-contents"}>
                                        <span>{END.perCount.even}번</span>
                                        <PerCount count={END.percent.even} />
                                    </p>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
                <div>
                    <main>
                        <div className={"graph-circle l-m-s"}>
                            <div className={"inner"}></div>
                            <Graph_4 l3={comb.percent.LEFT_3_EVEN} l4={comb.percent.LEFT_4_ODD} r3={comb.percent.RIGHT_3_ODD} r4={comb.percent.RIGHT_4_EVEN} />
                        </div>

                        <div className={"graph-count"}>
                            <div>
                                <span className={"--color-l3e"}>●</span>
                                <p>좌3짝 - {comb.perCount.LEFT_3_EVEN} <PerCount count={comb.percent.LEFT_3_EVEN} /> </p>
                            </div>
                            <div>
                                <span className={"--color-l4o"}>●</span>
                                <p>좌4홀 - {comb.perCount.LEFT_4_ODD} <PerCount count={comb.percent.LEFT_4_ODD} /> </p>
                            </div>
                            <div>
                                <span className={"--color-r3o"}>●</span>
                                <p>우3홀 - {comb.perCount.RIGHT_3_ODD} <PerCount count={comb.percent.RIGHT_3_ODD} /> </p>
                            </div>
                            <div>
                                <span className={"--color-r4e"}>●</span>
                                <p>우4짝 - {comb.perCount.RIGHT_4_EVEN} <PerCount count={comb.percent.RIGHT_4_EVEN} /> </p>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}
