import React, {ReactElement} from 'react';
import logo from "../../../asset/images/common/peeks_logo.svg";

function JoinTemplate(props: { children: React.ReactNode }) {
    return (
        <div className={"login-form"}>
            <header>
                <img src={logo} alt="logo"/>
            </header>
            <main>{props.children}</main>
        </div>
    );
}

export default JoinTemplate;