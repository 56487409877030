import React, {useContext, useEffect, useState} from 'react';
import {GAME_BOARD_TIMER, GameResult, VIEW_TYPE} from "../interface";
import GameBoardTimerCircleGraph from "./game_board_timer_circle_graph";
import {getStringMinute, MinigameContext} from "../../../../contexts/minigameProvider";
import {useInterval} from "usehooks-ts";
import {MinigameType} from "../../../../types/minigame";
import {getNowRound} from "../../../../components/gamePicker";

function GameBoardTimerCircle({gameResultFirst, viewType}:{ gameResultFirst: GameResult; viewType: VIEW_TYPE }) {
    const [round, setRound] = useState<number>(gameResultFirst.round_daily)

    const [showState, setShowState] = useState<boolean>(true)
    const [nowRound, setNowRound] = useState<number>(Number(round) + 1)

    const { gameType } = useContext(MinigameContext)

    useEffect(()=> {
        setShowState(true)
        const timerInterval = setInterval(()=> {
            setShowState(true)
        }, 1000)

        return () => {
            setShowState(false)
            clearTimeout(timerInterval)
        }
    }, [])
    useEffect(()=> {
        setNowRound(Number(round) + 1)
    }, [round])
    return (
        showState
            ? <div className={`board-timer-circle ${viewType}`}>
                  <div className={`inner-circle ${viewType}`}>
                      <TimerAndGraph gameType={gameType} viewType={viewType}/>
                  </div>
              </div>
            : <div></div>
    );
}
function TimerAndGraph({gameType, viewType}: {gameType: MinigameType; viewType: VIEW_TYPE}){
    // console.log('timer re render')
    const [nowRound, setNowRound] = useState<number>(getNowRound(new Date, gameType))
    const [gameCount, setGameCount] = useState<number>(0)
    const [timer, setTimer] = useState<string>("00:00")
    const [remain_percent, setRemain_percent] = useState<number>(0.00)
    const [readyState, setReadyState] = useState<boolean>(false)

    function makePercent(remain: number){
        const percent = Number((remain / GAME_BOARD_TIMER[gameType]).toFixed(4)) * 100
        return percent
    }
    useEffect(()=> {
        setGameCount(getStringMinute(gameType))
    }, [gameType])
    useInterval(()=> {
        // console.log(gameCount)
        if(gameCount < 1){
            setReadyState(true)
            setGameCount(getStringMinute(gameType))
        }else{
            setReadyState(false)
            setGameCount(gameCount - 1)
        }
        setNowRound(getNowRound(new Date, gameType))
        setRemain_percent(makePercent(gameCount))
    }, 1000)
    useEffect(()=> {
        setTimer(transMinutesCount(gameCount))
    }, [gameCount])
    return (
        <>
            <div className={"timer-state"}>
                <div className={"guide"}>
                    <p>{timer}</p>
                    <p>{nowRound}회 추첨 시작</p>
                </div>
            </div>
            <GameBoardTimerCircleGraph per={Number(remain_percent)} />
            { readyState
                ? <div className={`get-result-ready-pop ${viewType}`}>
                    <p>데이터를 받아오는 중 입니다.</p>
                </div>
                : <></> }
        </>
    )
}
export function transMinutesCount(num: number){
    let min = Math.floor(num / 60) < 10 ? "0" + Math.floor(num / 60) : Math.floor(num / 60)
    if( min < 5 == false ){
        min = "00";
    }
    const sec = (num % 60) < 10 ? "0" + num % 60 : num % 60
    // console.log(min, sec)
    if( min >= 0 && sec >= 0 ){
        return min+":"+sec
    }else{
        return "00:00"
    }
}
export default GameBoardTimerCircle;