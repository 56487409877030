import React, {useContext, useEffect, useState} from 'react';
import GameBoard from "./game_board";
import GameBoardGlobalTimer from "./game_board_global_timer";
import {FETCH_RESULT_STATUS_TYPE, GameResult, VIEW_TYPE} from "../interface";
import { BlockMinigame, getLeftTime } from "../index";
import GameBoardUserPick from "./game_board_user_pick";
import GameBoardResult from "./game_board_result";
import GameBoardStatistic from "./game_board_statistic";
import IconMenus from "./icon_menus";
import {IframeProvider } from './iframe_provider'
import {MinigameType} from "../../../../types/minigame";
import {MinigameContext} from "../../../../contexts/minigameProvider";
import moment from "moment";

export default function MiniGameBoardIframe({ game, viewType }: { game:MinigameType; viewType: VIEW_TYPE }) {
    const [fetchResultStatus, setFetchResultStatus] = useState<FETCH_RESULT_STATUS_TYPE>("NORMAL")
    const [blockPage, setBlock] = useState<boolean>(false)
    const [gameResults, setGameResults] = useState<GameResult[]>();
    const fetch_url = `/api/game/${game.split('_')[0]}/results/`
    const {setGameType} = useContext(MinigameContext)
    // const fetch_url = "http://127.0.0.1:8000/api/happyball/results/"

    useEffect(() => {
        setGameType(game)
        fetch(fetch_url)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setFetchResultStatus(data.status)
                    setGameResults(data.results);
                } else {
                    console.log('Game result Fetch Error');
                }
            })
    }, [])

    function fetchResults(): any {
        // console.log('fetch')
        if (!gameResults) {
            return
        }
        // console.log(gameResults.last())
        // 현재 state에 포함된 가장 마지막 라운드 이후 데이터를 요청
        const getRound = gameResults[0].round_daily + 1
        fetch(`${fetch_url}?date=${moment().format('YYYY-MM-DD')}&daily=${getRound}`)
            .then(async response => {
                if (response.ok) {
                    const data = await response.json();
                    setFetchResultStatus(data.status)
                    let fetchedResults = data.results;
                    if (fetchedResults.length > 0) {
                        if(fetchedResults.first().round_daily != gameResults.first().round_daily){
                            // console.log('result fetch')
                            setGameResults(fetchedResults.concat(gameResults.slice(0, -fetchedResults.length)));
                        }
                    }
                } else {
                    console.log('게임 결과 fetch 실패');
                }
            })
    }

    // 결과 값이 추가될 때마다 다음 결과값 요청을 위한 세팅
    // 다음 라운드 결과가 나올 때까지 기다렸다가 (getLeftTime())
    // 매 5초마다 요청(성공 시 clearInterval)
    useEffect(() => {
        if (!gameResults) {
            return
        }

        let intervalId: NodeJS.Timer
        let timeoutId = setTimeout(() => {
            fetchResults();
            intervalId = setInterval(fetchResults, 5000)
        }, getLeftTime(gameResults[0]?.game_draw_dt, gameResults[0]?.kind))

        if (fetchResultStatus == 'MAINTENANCE') {
            setBlock(true)
        } else if (fetchResultStatus == 'NORMAL') {
            setBlock(false)
        }

        return () => {
            clearTimeout(timeoutId)
            clearInterval(intervalId)
        }
    }, [gameResults])

    if (gameResults) {
        // console.log(gameResults.first())
        return (
            blockPage ? <BlockMinigame />
                : <IframeProvider>
                    <div id={"happyball-reel-game"} className={"happyball-layout"}>
                        <div className={"box-relative"}>
                            {/* 게임 배경 */}
                            <GameBoard gameResults={gameResults} game={game} viewType={viewType} />
                            {/* 아이콘 */}
                            <IconMenus viewType={viewType} kind={gameResults.first().kind} />
                            {/* 현재 시간 */}
                            <GameBoardGlobalTimer />


                            {/* 왼쪽 */}
                            {/*<GameBoardUserPick gameType={game} roundDate={moment(gameResults.first().game_draw_dt).format('YYYY-MM-DD')} round={gameResults.first().round_daily} />*/}
                            {/* 오른쪽 */}
                            <GameBoardResult gameResults={gameResults} viewType={viewType} />

                            {/* 아래 통계 */}
                            {/*<GameBoardStatistic gameResults={gameResults} viewType={viewType}/>*/}
                        </div>
                    </div>
                </IframeProvider>
        )
    } else {
        return <div>로딩중</div>
    }
}