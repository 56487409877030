import React from 'react';

export function GraphSML({s, m, l}: { s: number; m: number; l: number;  }) {
    const graphStyle = {
        backgroundImage: `${"conic-gradient("
            + "#2185D0 0%,"
            + "#2185D0 "+ s +"%,"
            + "#DB2828 "+ s +"%,"
            + "#DB2828 "+ (s + m) +"%,"
            + "#22DC00 "+ (s + m) +"%,"
            + "#22DC00 100%"
        + ")"}`
    };
    return (
        <div className={"graph"} style={graphStyle}></div>
    );
}


export function Graph_4({l3, l4, r3, r4}: { l3: number, l4: number, r3: number, r4: number }) {
    const graphStyle = {
        backgroundImage: `${"conic-gradient("
            + "#FF0000 0%,"
            + "#FF0000 "+ l3 +"%,"
            + "#0780FB "+ l3 +"%,"
            + "#0780FB "+ (l3 + l4) +"%,"
            + "#0059b1 "+ (l3 + l4) +"%,"
            + "#0059b1 "+ (l3 + l4 + r3) +"%,"
            + "#9c0000 "+ (l3 + l4 + r3) +"%,"
            + "#9c0000 100%"
        + ")"}`
    };
    return (
        <div className={"graph"} style={graphStyle}></div>
    );
}

