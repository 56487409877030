import React from "react";

export default function Btn( {type, children, onClick, className, rect, isSubmit}:
    { type: string; children: React.ReactNode; onClick: React.ReactEventHandler, className: string, rect: number[] | undefined; isSubmit: boolean} ){
    let btnRectSize = {};
    if(rect){
        btnRectSize = {
            width: rect[0],
            height: rect[1],
        }
    }
    return (
        <button className={`btn btn-${type} ${className}`}
                onClick={onClick}
                type={ isSubmit ? "submit" : undefined }
                style={btnRectSize}
        >
            {children}
        </button> )
}
Btn.defaultProps = {
    type: "theme",
    isSubmit: false,
    className: "",
    rect: undefined,
    onClick: ()=>{}
}

