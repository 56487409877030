import React, {useEffect, useState} from 'react';
import {userInterface} from "./interface";

type user_type = "level-" | "admin-"
function UserLevelNamecard({user}: { user: userInterface }) {
    const [userClass, setUserClass] = useState<user_type>("level-")
    const [userLevel, setUserLevel] = useState<number>(0)

    useEffect(()=> {
        if(user.admin_level){
            setUserClass('admin-')
            setUserLevel(user.admin_level)
        }else{
            setUserClass('level-')
            setUserLevel(user.level)
        }
    }, [user])
    return (
        <div className={"user-namecard"}>
            <i className={`--rank ${userClass}${userLevel}`}></i>
            {user.nickname}
        </div>
    );
}

export default UserLevelNamecard;