import React from 'react';

function Icon(props: {icon: string; size: number}) {
    return (
        <i className={"--icon " + props.icon + " --font-" + props.size}></i>
    );
}
Icon.defaultProps = {
    size: 10
}
export default Icon;