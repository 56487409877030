import React, {createContext, useState} from 'react';

const IframeContext = createContext({
    mute: false,
    toggleMute: ()=> {},

    mobileModalState: 0,
    toggleModalState: (num: number)=> {},
})

function IframeProvider( props: { children: React.ReactElement }) {
    const [mute, setMute] = useState<boolean>(false);
    const [mobileModalState, setModalState] = useState<number>(0)
    const toggleMute = (): void => {
        setMute(!mute)
    }
    const toggleModalState = (num: number) => {
        setModalState(num)
    }
    return (
        <IframeContext.Provider value={{ mute, toggleMute, mobileModalState, toggleModalState }} >
            {props.children}
        </IframeContext.Provider>
    );
}

export {IframeContext, IframeProvider};
