import React from 'react';
import { Outlet } from 'react-router-dom';
import { MinigameProvider } from "src/contexts/minigameProvider";

export default function SuperRootLayout() {
    return (
        <MinigameProvider>
            <Outlet/>
        </MinigameProvider>
    );
}