import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {Form, Button, Spinner, Modal} from "react-bootstrap";
import useUser from "src/hooks/useUser";
import api from "src/utils/api";
import JoinTemplate from "../../member/join_template";


interface ChangePasswordFormError {
    non_field_errors?: string[]
    password?: string[]
    new_password?: string[]
    new_password2?: string[]
}

interface ChangePasswordSerializer {
    username: string
    nickname: string
    isLoggedIn: boolean
}


export default function ChangePasswordModal({ show, setShow }: { show: boolean, setShow: any }) {
    const {user, mutateUser} = useUser()
    const navigate = useNavigate();
    const [formErrors, setFormErrors] = useState<ChangePasswordFormError>();
    const [submitting, setSubmitting] = useState<boolean>(false);
    const handleClose = () => {
        setShow(false);
    }

    useEffect(() => {
        fetch('/api/csrf-token');
    }, [])
    useEffect(()=>{

    },[submitting, formErrors])

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Body>
                <JoinTemplate>
                    <form onSubmit={async e => {
                        e.preventDefault();
                        setSubmitting(true);
                        const formData = new FormData(e.target as HTMLFormElement);
                        const response = await api('/api/user/change-password', {
                            method: "POST",
                            body: formData,
                        })
                        .then(res => res.json())
                        .then(data => {
                            mutateUser();
                            handleClose();
                            alert('비밀번호 변경완료');
                        })
                        .catch((err: ChangePasswordFormError) => setFormErrors(err))
                        .catch(err => alert(JSON.stringify(err)))
                        .finally(() => setSubmitting(false))
                    }}>
                        <div className={"form-row --mb-15"}>
                            <strong>기존 비밀번호</strong>
                            <input type="password" name={"password"} placeholder={"기존 비밀번호 입력"}/>
                            {formErrors?.password &&
                            <Form.Text style={{color: 'red', display: 'block'}}>{formErrors.password}</Form.Text>}
                        </div>
                        <div className={"form-row --mb-15"}>
                            <strong>새 비밀번호 입력</strong>
                            <input type="password" name={"new_password"} placeholder={"새 비밀번호 입력"}/>
                            {formErrors?.new_password &&
                            <Form.Text style={{color: 'red', display: 'block'}}>{formErrors.new_password}</Form.Text>}
                        </div>
                        <div className={"form-row --mb-15"}>
                            <strong>새 비밀번호 확인</strong>
                            <input type="password" name={"new_password2"} placeholder={"새 비밀번호 확인"}/>
                            {formErrors?.new_password2 &&
                            <Form.Text style={{color: 'red', display: 'block'}}>{formErrors.new_password2}</Form.Text>}
                        </div>

                        <button className={"btn btn-theme2 " + ( submitting ? "--disabled" : "" )} type={"submit"}>비밀번호 변경</button>
                    </form>

                </JoinTemplate>
            </Modal.Body>
        </Modal>
    );
}