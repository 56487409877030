import React, {useEffect, useState} from "react";
import {board_state, ball_obj} from "../game_board";
import SqsEffect from "../../Effect";
import AudioPlayer from "../audio_player";
import stop from "../../../asset/sound/Stop.mp3";

export default function Reels({board_state, ballNumber, kind}: {board_state: board_state; ballNumber: ball_obj; kind: string}){
    return (
        <>
            <div className={`board-logo ${kind}`}></div>
            <div className={`board-logo ${kind} effect`}></div>
            <div className={"board-effect " + board_state}></div>
            <div className={"spin-bg"}></div>
            <div className={"spin-bg effect " + board_state}>{/* 게임 끝날 때 add class: effect */}</div>
            <div className={"spin-container"}>
                <Spin index={1} state={board_state} number={ballNumber.normal[0]}/>
                <Spin index={2} state={board_state} number={ballNumber.normal[1]}/>
                <Spin index={3} state={board_state} number={ballNumber.normal[2]}/>
                <Spin index={4} state={board_state} number={ballNumber.normal[3]}/>
                <Spin index={5} state={board_state} number={ballNumber.normal[4]}/>
                <Spin index={6} state={board_state} number={ballNumber.powerball} powerball={true}/>
            </div>
        </>
    )
}

function Spin(props: { index: number; state: string; number: number; powerball: boolean; }) {
    const [audioPlay, setAudioPlay] = useState(false)
    const [spinState, setSpinState] = useState('ready')
    let addClass = "spin-key-" + props.index;
    if(props.powerball){
        addClass = " powerball " + addClass
    }
    const effect_name = "spin-"+props.index
    const effect = new SqsEffect(effect_name)
    const spin_effect_name = effect_name+"-effect"
    const spin_effect = new SqsEffect(spin_effect_name)

    useEffect(()=> {
        if( props.state === 'end' ){
            let count = props.index
            setTimeout(()=>{
                setSpinState(props.state)
            }, 1500 * count)
        }else{
            setTimeout(()=>{
                setSpinState(props.state)
            }, 0)
        }
    }, [props.state])
    useEffect(()=> {
        // console.log(props.number)
        let st = {
            imageSize : [800, 744],
            frame     : 60,
            vector    : [10, 6],
            speed     : 900,
            element   : ".spin-items.spin-key-"+props.index+" > .spin-figure",
            loop      : true,
        }
        if(props.powerball === true){
            st.imageSize = [940, 870]
        }
        if(spinState === 'spin'){
            effect.set(st)
            let st_2 = {
                imageSize : [800, 744],
                frame     : 60,
                vector    : [10, 6],
                speed     : 900,
                element   : ".spin-items.spin-key-"+props.index+" > .spin-effect",
                loop      : true,
                loop_end  : true,
            }
            if(props.powerball === true){
                st_2.imageSize = [940, 870]
            }
            spin_effect.set(st_2)
        }else if(spinState === 'ready'){
            effect.close(effect_name)
            spin_effect.close(spin_effect_name)
            spin_effect.reset(".spin-items.spin-key-"+props.index+" > .spin-effect")
            // return () => console.log(state);
        }else if(spinState === 'end'){
            effect.close(effect_name)
            spin_effect.close(spin_effect_name)
            spin_effect.reset(".spin-items.spin-key-"+props.index+" > .spin-effect")
            spinEnd()
            // setTimeout(function(){
            // }, 1000 * props.index)
        }
        // console.log(spinState)
    }, [spinState])
    function spinEnd(){
        let st = {
            imageSize : [400, 496],
            frame     : 20,
            vector    : [5, 4],
            speed     : 900,
            element   : ".spin-key-"+props.index+" > .spin-figure",
            loop      : false,
        }
        if(props.powerball === true){
            st.imageSize = [470, 580]
        }
        effect.set(st, function(){
            setAudioPlay(true)
            setTimeout(()=> {
                setAudioPlay(false)
            })
        })
    }
    return (
        <div className={"spin-items " + addClass}>
            <div className={"spin-effect " + addClass}></div>
            <AudioPlayer src={stop} play={audioPlay}/>
            {
                props.number >= 0 ? <figure className={`spin-figure ${spinState} number-${props.number}`} ></figure>
                    : <figure className={`spin-figure ${spinState}`} ></figure>
            }

        </div>
    );
}
Spin.defaultProps = {
    powerball: false,
    state: "ready",
    number: 0
}