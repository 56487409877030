import {createContext, useEffect, useState} from "react"
import { useLocation } from "react-router-dom"
import { MinigameType } from "src/types/minigame"
import {useInterval} from "usehooks-ts";
import {GAME_BOARD_TIMER_INTERFACE, GAME_COUNT_TYPE} from "../apps/minigame/happyball/interface";
import moment from "moment";

export interface MinigameContextType {
    gameType: MinigameType
    setGameType: (gameType: MinigameType) => void

    mobilePickState: boolean,
    SetMobilePickState: () => void
}

export const MiniGameDelay: GAME_BOARD_TIMER_INTERFACE = {
    happyball: 6,
    happyball_ladder: 6,
    eos5: -2,
    eos3: -2,
    pbg5: -1,
}
export const MinigameContext = createContext<MinigameContextType>({
    gameType: "happyball",
    setGameType: () => { },

    mobilePickState: false,
    SetMobilePickState: () => {}
})

export function getStringMinute(gameType: MinigameType){
    const now = new Date()
    let now_delay: number | Date  = now.getTime() - (MiniGameDelay[gameType] * 1000)
    now_delay = new Date(now_delay)

    const min = (GAME_COUNT_TYPE[gameType] - 1) - (now_delay.getMinutes() % GAME_COUNT_TYPE[gameType])
    let sec: number = Number(60 - now_delay.getSeconds())
    // if(sec == 60){
    //     sec = 0
    // }else if( sec < 10 ){
    //     sec = sec
    // }
    // console.log((min * 60) + sec)
    return (min * 60) + sec
}
export function MinigameProvider({ children }: { children: any }) {
    const [gameType, setGameType] = useState<MinigameType>('happyball')
    const [mobilePickState, setMobilePickState] = useState<boolean>(false)
    const location = useLocation()
    function SetMobilePickState(){
        setMobilePickState(!mobilePickState)
    }
    useEffect(()=> {
        if(location.pathname == "/"){
            setGameType('happyball')
        }else if( (location.pathname).split('/')[1] == 'minigame' ){
            setGameType((location.pathname).split('/')[2] as MinigameType)
        }else{
            return
        }
    }, [location.pathname])
    return (
        <MinigameContext.Provider value={{ gameType, setGameType, mobilePickState, SetMobilePickState}}>
            {children}
        </MinigameContext.Provider>
    )
}