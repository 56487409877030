import React, {useState} from 'react';

type justifyContent = "start" | "end" | "center" | "between" | "around";
type alignItems     = "start" | "end" | "center" | "stretch";
type direction      = "row" | "column" | "row-reverse" | "column-reverse"

const justifyContentSwitchStyle = {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    between: "space-between",
    around: "space-around",
}
const alignItemsSwitchStyle = {
    start: "flex-start",
    end: "flex-end",
    center: "center",
    stretch: "stretch",
}
function Flex({className, justifyContent, alignItems, direction, gap, children, inline=false}:
                  { className: string; justifyContent:justifyContent; alignItems: alignItems; direction: direction; gap: number; children: React.ReactNode; inline: boolean; }) {
    const [isInline, setInline] = useState<boolean>(inline)
    const inlineStyles = {
        justifyContent: justifyContentSwitchStyle[justifyContent],
        alignItems: alignItemsSwitchStyle[alignItems],
        flexDirection: direction,
        gridGap: (gap / 10)+"rem",
    }
    return (
        <div className={`${ isInline ? "d-inflex" : "d-flex" } ${className}`} style={inlineStyles}>{children}</div>
    );
}

Flex.defaultProps = {
    className: "",
    justifyContent: "start",
    alignItems: "center",
    direction: "row",
    gap: 0,
    children: undefined,
    inline: false,
}
export default Flex;