import React, {useContext, useEffect, useRef, useState} from 'react';
import {IframeContext} from "./iframe_provider";


function AudioPlayer({src, play, autoplay, loop}:{src: string, play: boolean, autoplay: boolean, loop: boolean}) {
    const { mute } = useContext(IframeContext)
    const [reRender, setReRender] = useState<boolean>(true)
    const audioPlay = useRef<HTMLAudioElement>(null)

    function audioPlayStart(){
        if(audioPlay.current && play){
            audioPlay.current.volume = 0.3;
            const audio_ = audioPlay.current.play()
            audio_.then(()=> {

            }).catch((e)=> {
                console.log(e)
                setTimeout(()=> {
                    setReRender(!reRender)
                }, 1000)
            })
        }
    }
    useEffect(()=>{
        if( play ){
            audioPlayStart()
        }
    }, [play])
    return (
        <audio src={src}
               ref={audioPlay}
               autoPlay={autoplay}
               loop={loop}
               muted={mute}
               typeof="audio/mp3"/>
    );
}
AudioPlayer.defaultProps = {
    src: "",
    play: false,
    autoplay: false,
    loop: false,
}

export default AudioPlayer;