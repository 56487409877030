import React from 'react';

function PopupTemplate({title, children}: {title: string, children: React.ReactNode}) {
    return (
        <div className={"room-popup-template"}>
            <header>{title}</header>
            <main>{children}</main>
        </div>
    );
}

export default PopupTemplate;