import {VIEW_TYPE} from "../../interface";
import React, {useEffect, useState} from "react";
import {board_state, ball_obj} from "../game_board";
import SqsEffect from "../../Effect";
import AudioPlayer from "../audio_player";
import ladderEnd from "../../../asset/sound/Stop.mp3";

export const getLadderResult = (num: number)=> {
    let START: 'LEFT' | 'RIGHT';
    let END: 'ODD' | 'EVEN';

    if( num % 2 == 1 ){
        START = "LEFT"

        if( num <= 14 ){
            END = "EVEN"
        }else{
            END = "ODD"
        }
    }else{
        START = "RIGHT"

        if( num <= 14 ){
            END = "ODD"
        }else{
            END = "EVEN"
        }
    }

    return START+"_"+END
}
export const getLadderLine = (num: number)=> {
    const ladder_result = getLadderResult(num)
    switch(ladder_result){
        default : throw new TypeError(`Not a pre-declared ladder result type`);
        case "LEFT_ODD" : return 4; break;
        case "LEFT_EVEN" : return 3; break;
        case "RIGHT_ODD" : return 3; break;
        case "RIGHT_EVEN" : return 4; break;
    }
}
type ladder_result_type = "LEFT_ODD" | "LEFT_EVEN" | "RIGHT_ODD" | "RIGHT_EVEN"
export default function Ladder({board_state, ballNumber, kind, viewType}: {board_state: board_state , ballNumber: ball_obj; kind: string; viewType: VIEW_TYPE}){

    return (
        <>
            <div className={`board-logo ${kind} ${viewType}`}></div>
            {/*{ board_state == 'stay' ? <Stay/> : <></> }*/}
            <Stay/>
            { board_state == 'spin' ? <Start firstNumber={ballNumber.normal[0]}/> : <></> }
            { board_state == 'end'  ? <Stay/> : <></> }
        </>
    )
}
function Start({firstNumber}:{firstNumber: number}){
    const [ladderResult, setLadderResult] = useState<ladder_result_type>()
    const [audioPlay, setAudioPlay] = useState<boolean>(false)
    useEffect(()=> {
        setLadderResult(getLadderResult(firstNumber) as ladder_result_type)
    }, [])
    useEffect(()=> {
        // console.log(ladderResult)
        const SQSEffect = new SqsEffect('ladder-bg.start')
        const stay_st = {
            imageSize : [3200, 1824],
            frame     : 60,
            vector    : [10, 6],
            speed     : 2000,
            element   : ".ladder-bg.start",
            loop      : false,
        }
        SQSEffect.set(stay_st)
        setTimeout(()=> {
            setAudioPlay(true)
        }, 1800)

        return ()=> {
            SQSEffect.close('ladder-bg.start')
        }
    }, [ladderResult])
    return (
        <>
            <div className={`ladder-bg start ${ladderResult}`}></div>
            <AudioPlayer src={ladderEnd} play={audioPlay}/>
        </>
    )
}
function Stay(){
    useEffect(()=> {
        const SQSEffect = new SqsEffect('ladder-bg.stay')
        const stay_st = {
            imageSize : [3200, 1520],
            frame     : 46,
            vector    : [10, 5],
            speed     : 2000,
            element   : ".ladder-bg.stay",
            loop      : true,
        }
        SQSEffect.set(stay_st)

        return ()=> {
            SQSEffect.close('ladder-bg.stay')
        }
    }, [])
    return (
        <div className={`ladder-bg stay`}></div>
    )
}