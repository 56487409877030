import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";
import { isMobile } from "react-device-detect";

export interface PrivateUser {
  uuid: string;
  username: string;
  nickname: string;
  is_authenticated: boolean;
  cash: number;
  point: number;
  exp: number;
  intro: string;
  rank: string;
  level: number;
  admin_level: number;
  profile_image: string;
  is_staff: boolean;
  is_anonymous: boolean;
  banner_image: string;
  new_message: number;
  inventory: {
    nickname_change: number;
    intro_change: number;
    count: number;
  };
  daily_info: {
    sent_message: number;
    message_limit: number;
    remain_message: number;
  };
  is_suspended: boolean;
}

async function fetcher(url: string) {
  return fetch(url).then((res) => res.json());
}

export default function useUser({ next = false, loginRequired = false } = {}) {
  const {
    data: user,
    mutate: mutateUser,
    isLoading,
  } = useSWR<PrivateUser>("/api/user/whoami", fetcher, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });
  const navigate = useNavigate();
  const nextUrl = window.location.pathname;

  useEffect(() => {
    // 로딩 중
    if (!user) {
      return;
    }

    // 로그인 필요 + 익명(비로그인) 상태
    if (loginRequired && user.is_anonymous) {
      alert("로그인 후 이용가능합니다.");

      return next ? navigate(`/user/login?next=${nextUrl}`, { replace: true }) : navigate("/user/login", { replace: true });
    }
  }, [user, next, loginRequired]);

  return { user, mutateUser, isLoading };
}
