import { useCallback, useRef, useState } from "react";
import { Button, Modal } from "react-bootstrap";

export function useCustomAlert() {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  // Alert가 닫힐 때 실행된다.
  const onCloseRef = useRef<any>();

  function handleClose() {
    setShow(false);
    if (onCloseRef.current) {
      onCloseRef.current();
    }
  }

  const AlertModal = useCallback(
    () => (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
      </Modal>
    ),
    [show, message]
  );

  function customAlert(message: string, onClose?: any) {
    setMessage(message);
    onCloseRef.current = onClose;
    setShow(true);
  }

  return { customAlert, AlertModal };
}

export function useCustomConfirm() {
  const [show, setShow] = useState<boolean>(false);
  const [message, setMessage] = useState<string>();
  // 확인 시 실행된다.
  const onConfirmRef = useRef<any>();

  function handleClose() {
    setShow(false);
  }

  function handleConfirm() {
    onConfirmRef.current();
    setShow(false);
  }

  const ConfirmModal = useCallback(
    () => (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{message}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            취소
          </Button>
          <Button variant="primary" onClick={handleConfirm}>
            확인
          </Button>
        </Modal.Footer>
      </Modal>
    ),
    [show, message]
  );

  function customConfirm(message: string, onConfirm: any) {
    setMessage(message);
    onConfirmRef.current = onConfirm;
    setShow(true);
  }

  return { customConfirm, ConfirmModal };
}
